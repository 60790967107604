import { inject, Injectable } from '@angular/core';
import { Logger } from '@shared';
import { RealEstateUsageEmbeddable } from '@generated/generatedEntities';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { EMPTY, take } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
const log = new Logger('MinergieEcoPresetService');
@Injectable({
  providedIn: 'root',
})
export class MinergieEcoPresetService {
  private http = inject(HttpClient);

  public presetMinergieEco2(usages: RealEstateUsageEmbeddable[], realEstateContainer: any) {
    return this.http.post(`${environment.serverUrl}/api/preset/minergie-eco`, usages, { responseType: 'text' }).pipe(
      take(1),
      tap((minergieEcoPreset) => {
        if (minergieEcoPreset === '1') {
          realEstateContainer.quality.energyStandard = 'MINERGIE_ECO';
        } else {
          realEstateContainer.quality.energyStandard = null;
        }
      }),
      map(() => realEstateContainer),
      catchError(() => {
        return EMPTY;
      }),
    );
  }
}
