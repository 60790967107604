import { Injectable } from '@angular/core';
import { Logger } from '@shared';
import { UsageTypeKvUsed } from '@generated/extended';

const log = new Logger('RoomHeightLimitsService');
@Injectable({
  providedIn: 'root',
})
export class RoomHeightLimitService {
  mapping: {
    [index in UsageTypeKvUsed]: number[];
  } = {
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__GEMEINDEHAEUSER: [],
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__RATHAUS_STADTHAUS_STADTVERWALTUNG: [],
    BUERO_HANDELSBAUTEN__BVB: [],
    BUERO_HANDELSBAUTEN__BVB__BUERO: [],
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK: [],
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__VERWALTUNG: [],
    BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM: [],
    BUERO_HANDELSBAUTEN__HANDELSBAU: [],
    BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__LADENBAU: [],
    BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE__MARKT_MESSEHALLE: [],
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN: [],
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN___GARDEROBENGEBAEUDE: [],
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__HALLENBAD: [],
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE: [],
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__TITLE: [],
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__PERSONAL_RESTAURANT: [],
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__RESTUARATION: [],
    GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL: [],
    GESUNDHEITSBAUTEN__TITLE: [],
    GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK: [],
    JUSTIZBAUTEN__TITLE: [],
    KULTUR__AUSSTELLUNGEN: [],
    KULTUR__TITLE: [],
    KULTUS__TITLE: [],
    LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN: [],
    LAGERBAUTEN__TITLE: [],
    LAGERBAUTEN__VERTEILZENTRALEN: [],
    LAGER_PRODUKTION__PRODUKTION: [],
    LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__STALLUNG: [],
    LANDWIRTSCHAFT__SCHEUNEN: [],
    LANDWIRTSCHAFT__TITLE: [],
    SCHULBAUTEN__BERUFSSCHULEN__BERUF: [],
    SCHULBAUTEN__BERUFSSCHULEN__GYM: [],
    SCHULBAUTEN__BIBLIOTHEKEN: [],
    SCHULBAUTEN__FORSCHUNGSBAUTEN: [],
    SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR: [],
    SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN: [],
    SCHULBAUTEN__TITLE: [],
    SCHUTZBAUTEN__FEUERWEHR: [],
    SCHUTZBAUTEN__TITLE: [],
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE: [],
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PARKHAUS: [],
    VERKEHRSBAUTEN__TITLE: [],
    VERKEHRSBAUTEN__WERKHOEFE: [],
    VERKEHRSBAUTEN__ZOLL: [],
    WOHNBAUTEN__PERSONALHAEUSER: [],
    WOHNBAUTEN__TITLE: [],
    // VERKEHRSBAUTEN__PARKHAEUSER_UNTERIRDISCH: [2.8, 3.0, 3.6],
    // FREIZEITBAUTEN_SPORTBAUTEN__HALLENBAEDER: [4.0, 6.0, 12.0],
    SCHULBAUTEN__KINDERGAERTEN_HORTE: [3.1, 4.0, 5.2],
    BUERO_HANDELSBAUTEN__BVB__BUERO__EDELROHBAU: [2.9, 3.5, 3.8],
    KULTUR__SAAL: [6.0, 10.0, 12.0],
    LAGERBAUTEN_MEHRSTOECKIGE: [2.8, 5.0, 10.0],
    // WOHNBAUTEN__TERRASSENHAEUSER_LOW: [2.7, 3.05, 3.4],
    SCHULBAUTEN__VOLKSSCHULEN: [3.1, 4.0, 5.2],
    // BUEROBAUTEN_VERWALTUNGSBAUTEN__GEMEINDEHAEUSER: [2.9, 3.5, 3.8],
    // WOHNBAUTEN__EFH_REIHEN_HIGH: [2.7, 3.05, 3.6],
    WOHNBAUTEN__MFH: [2.7, 3.05, 3.4],
    // BANKEN: [2.9, 3.5, 4.0],
    // WOHNBAUTEN__MFH_LOW: [2.7, 3.05, 3.4],
    WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME: [2.8, 3.4, 3.8],
    // WOHNBAUTEN__TERRASSENHAEUSER_MEDIUM: [2.7, 3.05, 3.4],
    // WOHNBAUTEN__MFH_MEDIUM: [2.7, 3.05, 3.4],
    // WOHNBAUTEN__EFH_REIHEN_LOW: [2.7, 3.05, 3.6],
    LAGER_PRODUKTION__PRODUKTION__PRODUKTIONSBAUTEN: [3.3, 4.0, 6.3],
    // FREIZEITBAUTEN_SPORTBAUTEN__GARDEROBENGEBAEUDE: [3.0, 4.0, 5.0],
    // LADENBAUTEN_HANDELSBAUTEN__GROSSHANDEL: [4.0, 4.7, 6.0],
    GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM: [2.8, 3.45, 3.8],
    // WOHNBAUTEN__STUDENTENWOHNHÄUSER: [2.8, 3.4, 3.8],
    WOHNBAUTEN__STUDENTENWOHNHAEUSER: [2.8, 3.4, 3.8],
    WOHNBAUTEN__ALTERSWOHNUNGEN: [2.7, 3.05, 3.4],
    SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN: [3.1, 4.0, 5.2],
    KULTUS__KIRCHGEMEINDEHAEUSER: [3.0, 3.9, 4.6],
    // WOHNBAUTEN__EFH_REIHEN_MEDIUM: [2.7, 3.05, 3.6],
    // WOHNBAUTEN__TERRASSENHAEUSER_HIGHEST: [2.7, 3.05, 3.4],
    WOHNBAUTEN__TERRASSENHAEUSER: [2.7, 3.05, 3.4],
    WOHNBAUTEN__ALTERSHEIME: [2.8, 3.4, 3.8],
    // LADENBAUTEN_VERWALTUNGSBAUTEN__RATHAEUSER: [2.9, 3.5, 3.8],
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE: [2.8, 3.0, 3.6],
    SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR: [3.1, 4.0, 5.2],
    LAGER_PRODUKTION__PRODUKTION__INDUSTRIEHALLEN: [5.0, 6.8, 10.5],
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL: [2.8, 3.4, 3.8],
    // WOHNBAUTEN__MFH_HIGH: [2.7, 3.05, 3.4],
    KULTUR__KULTUR_GEMEINSCHAFTSZENTREN: [3.5, 4.5, 6.0],
    KULTUR__MUSEEN: [4.0, 5.0, 10.0],
    BUERO_HANDELSBAUTEN__BVB__BUERO__VOLLAUSBAU: [2.9, 3.5, 3.8],
    WOHNBAUTEN__EFH_REIHEN: [2.7, 3.05, 3.6],
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE: [4.3, 7.5, 10.0],
    JUSTIZBAUTEN__JUSTIZVOLLZUG__STRAFVOLLZUG: [2.8, 3.3, 3.8],
  };

  public getMapping(): any {
    return this.mapping;
  }
}
