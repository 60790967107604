import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Logger } from '@app/@shared';
import { environment } from '@env/environment';
import { NullableRebuildEstateDTO } from '@generated/extended';
import {
  AdditionEntryDTO,
  OutRealEstateDTO,
  RealEstateUsageEmbeddable,
  RebuildEstateDTO,
  WithdrawDTO,
} from '@generated/generatedEntities';
import { BehaviorSubject, map, Observable, of, take } from 'rxjs';
import { ZipCodeTown } from '../realEstate/real-estate.service';
import { SharedControllerFnService } from '../shared/shared-controller-fn.service';
import locationJson from '../../../../assets/resources_generic/location.json';
import { catchError } from 'rxjs/operators';

const log = new Logger('RebuildEstateService');
@Injectable({
  providedIn: 'root',
})
/**
 * REST Service for api calls.
 */
export class RebuildEstateService {
  private http = inject(HttpClient);
  isDataLoadedResponse$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  getRealEstateById(id: number): Observable<OutRealEstateDTO> {
    return this.http.get<OutRealEstateDTO>(`${environment.serverUrl}/api/realestates/${id}`);
  }

  getEmpty(): Observable<RebuildEstateDTO> {
    return this.http.get<RebuildEstateDTO>(`${environment.serverUrl}/api/rebuild_estate/empty`);
  }

  getEmptyDamage(): Observable<RebuildEstateDTO> {
    return this.http.get<RebuildEstateDTO>(`${environment.serverUrl}/api/rebuild_estate/empty-damage`);
  }

  tryCalculation(realEstat: any): Observable<WithdrawDTO> {
    // console.log('this.testEstimations ==> ', realEstat);
    // localStorage.setItem('my_data', JSON.stringify(realEstat));
    return this.estimateBuildingMetrics(realEstat);
  }

  estimateBuildingMetrics(realEstate: NullableRebuildEstateDTO | RebuildEstateDTO): Observable<WithdrawDTO> {
    return this.http.post<WithdrawDTO>(`${environment.serverUrl}/api/rebuild_estate/estimate`, realEstate);
  }

  getZipCode(): ZipCodeTown[] {
    //return this.http.get<ZipCodeTown[]>('assets/resources_generic/location.json');
    return locationJson;
  }

  getCalculateResponse(): Observable<any | null> {
    return this.isDataLoadedResponse$.asObservable();
  }
}
