import { Injectable } from '@angular/core';
import { VentilationType } from '@generated/generatedEntities';

@Injectable({
  providedIn: 'root',
})
export class CompactVentilationTypeSelectService {
  baseGroup = 'Lüftung-, Kälteanlagen';
  sharedGroup = 'Bezug';

  NONE: { group: string; value: VentilationType } = { group: this.baseGroup, value: 'NONE' };
  EXHAUST_AIR: { group: string; value: VentilationType } = { group: this.baseGroup, value: 'EXHAUST_AIR' };
  SUPPLY_EXHAUST_AIR: { group: string; value: VentilationType } = {
    group: this.baseGroup,
    value: 'SUPPLY_EXHAUST_AIR',
  };
  UNDERGROUND_PARKING_AIR: { group: string; value: VentilationType } = {
    group: this.baseGroup,
    value: 'UNDERGROUND_PARKING_AIR',
  };
  CLIMA_PARTIAL: { group: string; value: VentilationType } = { group: this.baseGroup, value: 'CLIMA_PARTIAL' };
  CLIMA_FULL: { group: string; value: VentilationType } = { group: this.baseGroup, value: 'CLIMA_FULL' };

  SHARED_ANNEX: { group: string; value: VentilationType } = { group: this.sharedGroup, value: 'SHARED_ANNEX' };
  SHARED_ADDITION_STORY: { group: string; value: VentilationType } = {
    group: this.sharedGroup,
    value: 'SHARED_ADDITION_STORY',
  };
  SHARED_OVERHAUL: { group: string; value: VentilationType } = { group: this.sharedGroup, value: 'SHARED_OVERHAUL' };

  baseVentilationTypes = [
    this.NONE,
    this.EXHAUST_AIR,
    this.SUPPLY_EXHAUST_AIR,
    // UNDERGROUND_PARKING_AIR, // currently only in kennwerte-database
    this.CLIMA_PARTIAL,
    this.CLIMA_FULL,
  ];

  shared = [this.SHARED_ANNEX, this.SHARED_ADDITION_STORY, this.SHARED_OVERHAUL];

  unshareable = [this.EXHAUST_AIR];

  unshareables = this.unshareable;

  defaultData: { group: string; value: VentilationType }[] = this.baseVentilationTypes;
  rebuildIs = this.baseVentilationTypes;
  rebuildTarget = this.shared.concat(this.baseVentilationTypes);
}
