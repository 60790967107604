import { Component, Injectable, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { RoomHeightLimitsWarningService } from '@app/calculators/services/room-height/room-height-limits-warning.service';
import { RebuildEstateDataService } from '@app/core/services/rebuild/rebuild-estate-data/rebuild-estate-data.service';
import { RebuildGeometryService } from '@app/core/services/rebuild/rebuild-geometry/rebuild-geometry.service';
import { OutRealEstateDTO, RealEstateDTO, RebuildEstateDTO } from '@generated/generatedEntities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-room-height-directive',
  templateUrl: './room-height-directive.component.html',
  styleUrls: ['./room-height-directive.component.scss'],
})
export class RoomHeightDirectiveComponent implements OnInit, OnDestroy {
  private rebuildEstateDataService = inject(RebuildEstateDataService);
  private _rebuildGeometryService = inject(RebuildGeometryService);
  private _roomHeightLimitsWarningService = inject(RoomHeightLimitsWarningService);
  private _roomHeightDirectiveService = inject(RoomHeightDirectiveService);
  @Input() realEstateContainer!: any | RealEstateDTO | RebuildEstateDTO;
  isRoomHeightDisabled: boolean = true;
  roomHeightHasFocus: boolean = false;
  clickedRoomHeightEstimation = new BehaviorSubject<boolean | any>(false || null); //$rootScope.$on('clickedRoomHeightEstimation'
  changeInvestments = new BehaviorSubject<boolean | any>(false || null); //$rootScope.$broadcast('changeInvestments',
  AreaChange = new BehaviorSubject<boolean | any>(false || null); //$rootScope.$broadcast('changeInvestments',

  ngOnDestroy(): void {
    // this.onClickedRoomHeight();
    this.onAreaChange();
  }

  ngOnInit(): void {
    this.isRoomHeightDisabled = true;
    this._roomHeightDirectiveService.isRoomHeighDisabled$.next(true);
    this.detechChangesInObservables();
  }

  intitializeData() {
    this.CalcTotalFloorArea416(this.realEstateContainer.geometry.roomHeight);
    this.clickedRoomHeightEstimation.next(true);
    this.changeInvestments.next(true);
  }

  detechChangesInObservables() {
    // Detecting changes in "clickedRoomHeightEstimation"
    this.clickedRoomHeightEstimation.pipe(untilDestroyed(this)).subscribe({
      next: (isClickedRoomHeight) => {
        if (isClickedRoomHeight) {
          this.onClickedRoomHeight();
        }
      },
    });

    // Detecting changes in "AreaChange"
    this.AreaChange.pipe(untilDestroyed(this)).subscribe({
      next: (isAreaChange) => {
        if (isAreaChange) {
          this.onAreaChange();
        }
      },
    });
  }

  setValuesOnClickEvent() {
    this.realEstateContainer.geometry.roomHeight = 2.9;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      this.realEstateContainer.targetOverhaul.roomHeight = 2.9;

      if (this._rebuildGeometryService.service.isAdditionStory) {
        this.realEstateContainer.targetAdditionStory.roomHeight = 2.9;
      }
      if (this._rebuildGeometryService.service.isAnnex) {
        this.realEstateContainer.targetAnnex.roomHeight = 2.9;
      }
    }
    // set variable
    this.intitializeData();
    //  $timeout(function() {
    //      scope.$apply();
    //      $rootScope.$broadcast('windowSizeChange', {
    //          width: $(document).width
    //      });
    //  }, 100);
  }

  parseToFloat(value: string | any) {
    if (value != null) {
      if (value.length > 2 && value.search(',') !== -1) {
        value = value.replace(',', '.');
      }
      value = parseFloat(value);
      return value;
    } else {
      return null;
    }
  }

  CalcTotalFloorArea416(roomHeight: number | any, RebuildGeometryService?: RebuildGeometryService | any) {
    roomHeight = this.parseToFloat(roomHeight);
    let gf;
    if (this.realEstateContainer.geometry.totalVolume416 && roomHeight > 0) {
      gf = Math.round(this.realEstateContainer.geometry.totalVolume416 / roomHeight);
      if (gf > 0) {
        this.realEstateContainer.geometry.totalFloorArea416 = gf;
      }
    }
  }

  CalcVolume416(roomHeight: number | any) {
    roomHeight = this.parseToFloat(roomHeight);
    if (this.realEstateContainer.geometry.totalFloorArea416 && roomHeight > 0) {
      var gv = Math.round(this.realEstateContainer.geometry.totalFloorArea416 * roomHeight);
      if (gv > 0) {
        this.realEstateContainer.geometry.totalVolume416 = gv;
      }
    }
  }

  genericCalcVolume416(totalFloorArea416: number | any, roomHeight: number | any, totalVolume416: number | any) {
    roomHeight = this.parseToFloat(roomHeight);
    if (totalFloorArea416 && roomHeight > 0) {
      var gv = Math.round(totalFloorArea416 * roomHeight);
      if (gv > 0) {
        totalVolume416 = gv;
        return totalVolume416;
      }
    }
  }

  CalcRoomHeight(area416: number | any, volume416: number | any) {
    if (area416 > 0 && volume416 > 0) {
      return Math.max(0, Math.round((volume416 * 100) / area416) / 100);
    } else {
      return null;
    }
  }

  /** This is the button that would set values to the field in DAMAGE FORM */
  SetRoomHeightValues() {
    // fetch room height
    this.realEstateContainer.geometry.roomHeight = 2.9;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      this.realEstateContainer.targetOverhaul.roomHeight = 2.9;

      if (this._rebuildGeometryService.service.isAdditionStory) {
        this.realEstateContainer.targetAdditionStory.roomHeight = 2.9;
      }
      if (this._rebuildGeometryService.service.isAnnex) {
        this.realEstateContainer.targetAnnex.roomHeight = 2.9;
      }
    }
    // set variable
    this.CalcTotalFloorArea416(this.realEstateContainer.geometry.roomHeight, this._rebuildGeometryService);
    //$rootScope.$broadcast('changeInvestments', {});
    //$rootScope.$broadcast('clickedRoomHeightEstimation', {});
  }

  onClickedRoomHeight() {
    this.isRoomHeightDisabled = false;
    this._roomHeightDirectiveService.isRoomHeighDisabled$.next(false);
  }

  onAreaChange(event?: any, data?: any) {
    //FIXME Aniket please verify. :)
    if (data != null) {
      this.realEstateContainer.geometry.totalFloorArea416 = data.area416;
      this.realEstateContainer.geometry.totalVolume416 = data.volume416;
      if (this.realEstateContainer.metaData.withdrawalProductType == 'BUILDING_INSURANCE_VALUES') {
        this.realEstateContainer.geometry.totalVolume116 = data.volume116;
      }
      if (data.usages.length > 1) {
        var i = 0;
        data.usages.forEach((u: any) => {
          this.realEstateContainer.usages[i].percentage = u.percentage;
          i++;
        });
      }
    }
    this._roomHeightLimitsWarningService.showWarningIfOutOfLimit(this.realEstateContainer);
  }
  // $scope.$watch('realEstateContainer.geometry.roomHeight', function(roomHeight, oldVal) {
  onChangeRealestateContainerGeometeryRoomHeight(roomHeight: any, oldVal?: any) {
    // this.realEstateContainer = realEstateContainer;
    this.realEstateContainer = roomHeight;
    if (!this.isRoomHeightDisabled && roomHeight) {
      this.CalcTotalFloorArea416(roomHeight);
    }
  }

  // $scope.$watch('[realEstateContainer.geometry.totalFloorArea416,realEstateContainer.geometry.totalVolume416]', function(area416, oldValue) {
  onChangeRealestateContainerGeometeryTotalFloorAreaVolume416(realestate: any, newVal?: any, oldValue?: any) {
    this.realEstateContainer = realestate;
    if (
      !this.realEstateContainer.geometry.totalFloorArea416 ||
      isNaN(Number(this.realEstateContainer.geometry.totalFloorArea416)) ||
      !this.realEstateContainer.geometry.totalVolume416 ||
      isNaN(Number(this.realEstateContainer.geometry.totalVolume416))
    ) {
      this.isRoomHeightDisabled = true;
      this._roomHeightDirectiveService.isRoomHeighDisabled$.next(true);
      this.realEstateContainer.geometry.roomHeight = undefined;
    } else {
      var roomHeight = this.CalcRoomHeight(
        this.realEstateContainer.geometry.totalFloorArea416,
        this.realEstateContainer.geometry.totalVolume416,
      );
      if (roomHeight != null) {
        this.realEstateContainer.geometry.roomHeight = roomHeight;
      }
    }
  }

  genericCalcRoomHeightFromVolume(totalFloorArea416: number, roomHeight: number, volume416: number) {
    if (totalFloorArea416 && volume416 > 0) {
      var r = Math.round((volume416 * 100) / totalFloorArea416) / 100;
      if (r > 0) {
        return r;
      }
    }
    return roomHeight;
  }

  genericCalcRoomHeightFromArea(totalFloorArea416: number, roomHeight: number, volume416: number) {
    if (volume416 && totalFloorArea416 > 0) {
      var r = Math.round((volume416 * 100) / totalFloorArea416) / 100;
      if (r > 0) {
        return r;
      }
    }
    return roomHeight;
  }

  genericCalcTotalFloorArea() {
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      var gf;

      var roomHeightOverhaul = this.parseToFloat(this.realEstateContainer.targetOverhaul.roomHeight);
      if (this.realEstateContainer.targetOverhaul.totalVolume416 && roomHeightOverhaul > 0) {
        gf = Math.round(this.realEstateContainer.targetOverhaul.totalVolume416 / roomHeightOverhaul);
        if (gf > 0) {
          this.realEstateContainer.targetOverhaul.totalFloorArea416 = gf;
        }
      }

      if (this._rebuildGeometryService.service.isAdditionStory) {
        var roomHeightAdditionStory = this.parseToFloat(this.realEstateContainer.targetAdditionStory.roomHeight);
        if (this.realEstateContainer.targetAdditionStory.totalVolume416 && roomHeightAdditionStory > 0) {
          gf = Math.round(this.realEstateContainer.targetAdditionStory.totalVolume416 / roomHeightAdditionStory);
          if (gf > 0) {
            this.realEstateContainer.targetAdditionStory.totalFloorArea416 = gf;
          }
        }
      }

      if (this._rebuildGeometryService.service.isAnnex) {
        var roomHeightAnnex = this.parseToFloat(this.realEstateContainer.targetAnnex.roomHeight);
        if (this.realEstateContainer.targetAnnex.totalVolume416 && roomHeightAnnex > 0) {
          gf = Math.round(this.realEstateContainer.targetAnnex.totalVolume416 / roomHeightAnnex);
          if (gf > 0) {
            this.realEstateContainer.targetAnnex.totalFloorArea416 = gf;
          }
        }
      }
    }
  }

  // // this.$watch('realEstateContainer.geometry.roomHeight'
  // onChangeGeometryRoomHeight(roomHeight: any | number, oldVal?: any) {
  //   // this.realEstateContainer.geometry.roomHeight
  //   if (
  //     this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
  //     this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS'
  //   ) {
  //     if (!this.isRoomHeightDisabled ){//&& realestate.geometry.roomHeight != oldVal) {
  //       this.CalcTotalFloorArea416(roomHeight, this._rebuildGeometryService);
  //     }
  //   }
  // }

  // this.$watch('[realEstateContainer.geometry.totalFloorArea416,realEstateContainer.geometry.totalVolume416]
  onChangeGeometryTotalFloorArea416TotalFloorArea416(realestate: any, area416?: any | number, oldValue?: any) {
    this.realEstateContainer = realestate;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      if (
        !this.realEstateContainer.geometry.totalFloorArea416 ||
        isNaN(Number(this.realEstateContainer.geometry.totalFloorArea416)) ||
        !this.realEstateContainer.geometry.totalVolume416 ||
        isNaN(Number(this.realEstateContainer.geometry.totalVolume416))
      ) {
        this.isRoomHeightDisabled = true;
        this._roomHeightDirectiveService.isRoomHeighDisabled$.next(true);
        this.realEstateContainer.geometry.roomHeight = undefined;
      } else {
        var roomHeight = this.CalcRoomHeight(
          this.realEstateContainer.geometry.totalFloorArea416,
          this.realEstateContainer.geometry.totalVolume416,
        );
        var roomHeightTargetOverhaul = this.CalcRoomHeight(
          this.realEstateContainer.targetOverhaul.totalFloorArea416,
          this.realEstateContainer.targetOverhaul.totalVolume416,
        );
        if (roomHeight != null) {
          this.realEstateContainer.geometry.roomHeight = roomHeight;
        }
        if (roomHeightTargetOverhaul != null) {
          this.realEstateContainer.targetOverhaul.roomHeight = roomHeightTargetOverhaul;
        }
      }
    }
  }

  // this.$watch('realEstateContainer.targetOverhaul.totalFloorArea416'
  OnChangeTargetOverhaulTotalFloorArea416(realEstateContainer: any, area416: any | number, oldValue?: any) {
    this.realEstateContainer = realEstateContainer;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      if (
        !this.realEstateContainer.targetOverhaul.totalFloorArea416 ||
        isNaN(Number(this.realEstateContainer.targetOverhaul.totalFloorArea416))
      ) {
        this.isRoomHeightDisabled = true;
        this._roomHeightDirectiveService.isRoomHeighDisabled$.next(true);
        this.realEstateContainer.targetOverhaul.roomHeight = undefined;
      } else if (!this.roomHeightHasFocus) {
        this.realEstateContainer.targetOverhaul.roomHeight = this.genericCalcRoomHeightFromArea(
          area416,
          this.realEstateContainer.targetOverhaul.roomHeight,
          this.realEstateContainer.targetOverhaul.totalVolume416,
        );
      }
    }
  }

  //this.$watch('realEstateContainer.targetAdditionStory.totalFloorArea416', function(area416, oldValue)
  OnChangeTargetAdditionStoryTotalFloorArea416(realEstateContainer: any, area416: any | number, oldValue?: any) {
    this.realEstateContainer = realEstateContainer;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS'
    ) {
      if (
        !this.realEstateContainer.targetAdditionStory.totalFloorArea416 ||
        isNaN(Number(this.realEstateContainer.targetAdditionStory.totalFloorArea416))
      ) {
        this.isRoomHeightDisabled = true;
        this._roomHeightDirectiveService.isRoomHeighDisabled$.next(true);
        this.realEstateContainer.targetAdditionStory.roomHeight = undefined;
      } else if (!this.roomHeightHasFocus) {
        this.realEstateContainer.targetAdditionStory.roomHeight = this.genericCalcRoomHeightFromArea(
          area416,
          this.realEstateContainer.targetAdditionStory.roomHeight,
          this.realEstateContainer.targetAdditionStory.totalVolume416,
        );
      }
    }
  }

  // this.$watch('realEstateContainer.targetAnnex.totalFloorArea416', function(area416, oldValue)
  OnChangeTargetAnnexTotalFloorArea416(realEstateContainer: any, area416: any | number, oldValue?: any) {
    this.realEstateContainer = realEstateContainer;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      if (
        !this.realEstateContainer.targetAnnex.totalFloorArea416 ||
        isNaN(Number(this.realEstateContainer.targetAnnex.totalFloorArea416))
      ) {
        this.isRoomHeightDisabled = true;
        this._roomHeightDirectiveService.isRoomHeighDisabled$.next(true);
        this.realEstateContainer.targetAnnex.roomHeight = undefined;
      } else if (!this.roomHeightHasFocus) {
        this.realEstateContainer.targetAnnex.roomHeight = this.genericCalcRoomHeightFromArea(
          area416,
          this.realEstateContainer.targetAnnex.roomHeight,
          this.realEstateContainer.targetAnnex.totalVolume416,
        );
      }
    }
  }

  //this.$watch('realEstateContainer.targetOverhaul.totalVolume416', function(volume416, oldValue)
  OnChangeTargetOverhaulTotalVolume416(realEstateContainer: any, volume416: any, oldValue?: any) {
    this.realEstateContainer = realEstateContainer;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      if (!this.roomHeightHasFocus) {
        if (!volume416) {
          this.isRoomHeightDisabled = true;
          this._roomHeightDirectiveService.isRoomHeighDisabled$.next(true);
          this.realEstateContainer.targetOverhaul.roomHeight = undefined;
        } else {
          this.realEstateContainer.targetOverhaul.roomHeight = this.genericCalcRoomHeightFromVolume(
            this.realEstateContainer.targetOverhaul.totalFloorArea416,
            this.realEstateContainer.targetOverhaul.roomHeight,
            volume416,
          );
        }
      }
    }
  }

  //this.$watch('realEstateContainer.targetAdditionStory.totalVolume416', function(volume416, oldValue) {
  OnChangeTargetAdditionStoryTotalVolume416(realEstateContainer: any, volume416: any, oldValue?: any) {
    this.realEstateContainer = realEstateContainer;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      if (!this.roomHeightHasFocus) {
        if (!volume416) {
          this.isRoomHeightDisabled = true;
          this._roomHeightDirectiveService.isRoomHeighDisabled$.next(true);
          this.realEstateContainer.targetAdditionStory.roomHeight = undefined;
        } else {
          this.realEstateContainer.targetAdditionStory.roomHeight = this.genericCalcRoomHeightFromVolume(
            this.realEstateContainer.targetAdditionStory.totalFloorArea416,
            this.realEstateContainer.targetAdditionStory.roomHeight,
            volume416,
          );
        }
      }
    }
  }

  //this.$watch('realEstateContainer.targetAnnex.totalVolume416', function(volume416, oldValue) {
  OnChangeTargetAnnexTotalVolume416(realEstateContainer: any, volume416: any, oldValue?: any) {
    this.realEstateContainer = realEstateContainer;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      if (!this.roomHeightHasFocus) {
        if (!volume416) {
          this.isRoomHeightDisabled = true;
          this._roomHeightDirectiveService.isRoomHeighDisabled$.next(true);
          this.realEstateContainer.targetAnnex.roomHeight = undefined;
        } else {
          this.realEstateContainer.targetAnnex.roomHeight = this.genericCalcRoomHeightFromVolume(
            this.realEstateContainer.targetAnnex.totalFloorArea416,
            this.realEstateContainer.targetAnnex.roomHeight,
            volume416,
          );
        }
      }
    }
  }

  //this.$watch('realEstateContainer.targetOverhaul.roomHeight', function(roomHeight, oldValue) {
  onChangeTargetOverhaulRoomHeight(realEstateContainer: any, roomHeight?: any, oldValue?: any) {
    this.realEstateContainer = realEstateContainer;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      if (!this.isRoomHeightDisabled) {
        //&& roomHeight != oldValue) {
        this.genericCalcTotalFloorArea();
      }
    }
  }

  //this.$watch('realEstateContainer.targetAdditionStory.roomHeight', function(roomHeight, oldValue) {
  onChangeTargetAdditionStoryRoomHeight(realEstateContainer: any, roomHeight?: any, oldValue?: any) {
    this.realEstateContainer = realEstateContainer;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      if (!this.isRoomHeightDisabled) {
        //&& roomHeight != oldValue) {
        this.genericCalcTotalFloorArea();
      }
    }
  }

  //this.$watch('realEstateContainer.targetAnnex.roomHeight', function(roomHeight, oldValue) {
  onChangeTargetAnnexToomHeight(realEstateContainer: any, roomHeight?: any, oldValue?: any) {
    this.realEstateContainer = realEstateContainer;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      if (!this.isRoomHeightDisabled) {
        //&& roomHeight != oldValue) {
        this.genericCalcTotalFloorArea();
      }
    }
  }
}

@Injectable({
  providedIn: 'root',
})
/**
 * Data access with a local cache.
 */
export class RoomHeightDirectiveService {
  realEstateContainer!: RebuildEstateDTO;
  isRoomHeightDisabled: boolean = true;
  public isRoomHeighDisabled$ = new BehaviorSubject<boolean>(true); //new Observable to keep track of value change
  // this.$watch('[realEstateContainer.geometry.totalFloorArea416,realEstateContainer.geometry.totalVolume416]
  onChangeGeometryTotalFloorArea416TotalFloorArea416(
    realestate: RebuildEstateDTO,
    area416?: any | number,
    oldValue?: any,
  ) {
    this.realEstateContainer = realestate;
    if (
      this.realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      this.realEstateContainer.metaData.withdrawalProductType === 'LCC'
    ) {
      if (
        !this.realEstateContainer.geometry.totalFloorArea416 ||
        isNaN(Number(this.realEstateContainer.geometry.totalFloorArea416)) ||
        !this.realEstateContainer.geometry.totalVolume416 ||
        isNaN(Number(this.realEstateContainer.geometry.totalVolume416))
      ) {
        this.isRoomHeightDisabled = true;
        this.isRoomHeighDisabled$.next(true);
        this.realEstateContainer.geometry!['roomHeight'] = 0;
        this.realEstateContainer.targetAnnex!['roomHeight'] = undefined;
        this.realEstateContainer.targetOverhaul!['roomHeight'] = undefined;
        this.realEstateContainer.targetAdditionStory!['roomHeight'] = undefined;
      } else {
        var roomHeight = this.CalcRoomHeight(
          this.realEstateContainer.geometry.totalFloorArea416,
          this.realEstateContainer.geometry.totalVolume416,
        );
        var roomHeightTargetOverhaul = this.CalcRoomHeight(
          this.realEstateContainer.targetOverhaul.totalFloorArea416,
          this.realEstateContainer.targetOverhaul.totalVolume416,
        );
        var roomHeightAnnex = this.CalcRoomHeight(
          this.realEstateContainer.targetAnnex?.totalFloorArea416,
          this.realEstateContainer.targetAnnex?.totalVolume416,
        );
        var roomHeightAdditionalStory = this.CalcRoomHeight(
          this.realEstateContainer.targetAdditionStory?.totalFloorArea416,
          this.realEstateContainer.targetAdditionStory?.totalVolume416,
        );
        if (roomHeight != null) {
          this.realEstateContainer.geometry.roomHeight = roomHeight;
        }
        if (roomHeightTargetOverhaul != null) {
          this.realEstateContainer.targetOverhaul['roomHeight'] = roomHeight;
        }
        if (roomHeightAnnex != null) {
          this.realEstateContainer.targetAnnex!['roomHeight'] = roomHeight;
        }
        if (roomHeightAdditionalStory != null) {
          this.realEstateContainer.targetAdditionStory!['roomHeight'] = roomHeight;
        }
      }
    }
  }

  CalcRoomHeight(area416: number | any, volume416: number | any) {
    if (area416 > 0 && volume416 > 0) {
      return Math.max(0, Math.round((volume416 * 100) / area416) / 100);
    } else {
      return null;
    }
  }
}
