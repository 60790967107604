import { Injectable } from '@angular/core';
import { Logger } from '@shared';
import { UsageStandardUsed, UsageTypeKvUsed } from '@generated/extended';
// import usageSliderMapping from './../../preset/json_data/usage-slider-mapping-final.json'

const log = new Logger('UsageSliderMappingService');

export type StandardToValuesMap = {
  [index in UsageStandardUsed]: number[];
};
export type UsageStandardSliderMap = {
  [index in UsageTypeKvUsed]: StandardToValuesMap;
};

@Injectable({
  providedIn: 'root',
})
export class UsageSliderMappingService {
  public getMapping(): UsageStandardSliderMap {
    return this.mapping;
  }

  private mapping: UsageStandardSliderMap = {
    BUERO_HANDELSBAUTEN__BVB: { LOW: [], MEDIUM: [], HIGH: [] },
    BUERO_HANDELSBAUTEN__BVB__BUERO: { LOW: [], MEDIUM: [], HIGH: [] },
    BUERO_HANDELSBAUTEN__HANDELSBAU: { LOW: [], MEDIUM: [], HIGH: [] },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN: { LOW: [], MEDIUM: [], HIGH: [] },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__TITLE: { LOW: [], MEDIUM: [], HIGH: [] },
    GESUNDHEITSBAUTEN__TITLE: { LOW: [], MEDIUM: [], HIGH: [] },
    GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK: { LOW: [], MEDIUM: [], HIGH: [] },
    JUSTIZBAUTEN__TITLE: { LOW: [], MEDIUM: [], HIGH: [] },
    KULTUR__TITLE: { LOW: [], MEDIUM: [], HIGH: [] },
    KULTUS__TITLE: { LOW: [], MEDIUM: [], HIGH: [] },
    LAGERBAUTEN__TITLE: { LOW: [], MEDIUM: [], HIGH: [] },
    LAGER_PRODUKTION__PRODUKTION: { LOW: [], MEDIUM: [], HIGH: [] },
    LANDWIRTSCHAFT__TITLE: { LOW: [], MEDIUM: [], HIGH: [] },
    SCHULBAUTEN__FORSCHUNGSBAUTEN: { LOW: [], MEDIUM: [], HIGH: [] },
    SCHULBAUTEN__TITLE: { LOW: [], MEDIUM: [], HIGH: [] },
    SCHUTZBAUTEN__TITLE: { LOW: [], MEDIUM: [], HIGH: [] },
    VERKEHRSBAUTEN__TITLE: { LOW: [], MEDIUM: [], HIGH: [] },
    WOHNBAUTEN__TITLE: { LOW: [], MEDIUM: [], HIGH: [] },
    WOHNBAUTEN__MFH: {
      LOW: [3, 3, 3, 3, 3, 3, 3, 3, 2, 2],
      MEDIUM: [3, 3, 3, 3, 4, 3, 4, 4, 3, 3],
      HIGH: [4, 3, 3, 4, 5, 4, 5, 5, 4, 3],
    },
    WOHNBAUTEN__TERRASSENHAEUSER: {
      LOW: [3, 5, 3, 3, 5, 3, 3, 4, 2, 2],
      MEDIUM: [4, 5, 3, 3, 5, 3, 4, 5, 3, 3],
      HIGH: [4, 5, 3, 4, 5, 4, 5, 5, 4, 4],
    },
    WOHNBAUTEN__EFH_REIHEN: {
      LOW: [3, 3, 3, 2, 3, 3, 3, 4, 2, 2],
      MEDIUM: [3, 3, 3, 3, 4, 3, 4, 4, 3, 3],
      HIGH: [4, 3, 3, 4, 5, 4, 5, 5, 4, 4],
    },
    WOHNBAUTEN__ALTERSWOHNUNGEN: {
      LOW: [3, 3, 3, 3, 3, 3, 3, 4, 3, 2],
      MEDIUM: [3, 3, 3, 3, 4, 4, 4, 5, 3, 3],
      HIGH: [4, 3, 3, 4, 4, 4, 5, 5, 4, 3],
    },
    WOHNBAUTEN__ALTERSHEIME: {
      LOW: [4, 3, 3, 3, 3, 4, 4, 4, 3, 3],
      MEDIUM: [5, 3, 3, 3, 4, 5, 5, 5, 4, 4],
      HIGH: [5, 3, 3, 4, 4, 5, 5, 5, 5, 4],
    },
    WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME: {
      LOW: [3, 3, 3, 3, 3, 4, 4, 4, 3, 3],
      MEDIUM: [4, 3, 3, 3, 4, 5, 5, 5, 4, 4],
      HIGH: [4, 3, 3, 3, 4, 5, 5, 5, 5, 4],
    },
    WOHNBAUTEN__STUDENTENWOHNHAEUSER: {
      LOW: [3, 3, 3, 3, 3, 3, 3, 4, 3, 2],
      MEDIUM: [4, 4, 3, 3, 4, 4, 4, 4, 4, 3],
      HIGH: [4, 4, 3, 3, 4, 4, 5, 5, 5, 3],
    },
    WOHNBAUTEN__PERSONALHAEUSER: {
      LOW: [3, 3, 3, 2, 2, 3, 3, 4, 2, 2],
      MEDIUM: [3, 3, 3, 3, 3, 3, 4, 4, 2, 2],
      HIGH: [4, 3, 3, 3, 4, 4, 5, 5, 3, 3],
    },
    SCHULBAUTEN__KINDERGAERTEN_HORTE: {
      LOW: [4, 3, 3, 3, 3, 4, 3, 2, 4, 3],
      MEDIUM: [4, 3, 3, 3, 4, 4, 5, 3, 4, 4],
      HIGH: [4, 3, 3, 4, 4, 5, 5, 3, 5, 5],
    },
    SCHULBAUTEN__VOLKSSCHULEN: {
      LOW: [3, 3, 3, 4, 3, 4, 4, 2, 3, 3],
      MEDIUM: [4, 3, 3, 4, 3, 4, 5, 2, 4, 4],
      HIGH: [4, 3, 4, 4, 4, 5, 5, 3, 5, 5],
    },
    SCHULBAUTEN__BERUFSSCHULEN__BERUF: {
      LOW: [3, 3, 3, 4, 3, 4, 5, 2, 3, 3],
      MEDIUM: [3, 3, 3, 4, 4, 5, 5, 2, 4, 4],
      HIGH: [4, 3, 4, 5, 4, 5, 5, 3, 4, 5],
    },
    SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN: {
      LOW: [4, 3, 3, 4, 3, 4, 5, 2, 4, 3],
      MEDIUM: [5, 3, 3, 4, 4, 4, 5, 3, 4, 4],
      HIGH: [5, 3, 4, 4, 4, 5, 5, 3, 5, 5],
    },
    SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN: {
      LOW: [3, 3, 3, 4, 3, 5, 5, 2, 3, 3],
      MEDIUM: [3, 3, 3, 4, 4, 5, 5, 2, 4, 4],
      HIGH: [4, 3, 4, 5, 4, 6, 5, 3, 5, 5],
    },
    SCHULBAUTEN__BIBLIOTHEKEN: {
      LOW: [3, 2, 3, 3, 1, 4, 5, 2, 2, 2],
      MEDIUM: [3, 3, 4, 4, 1, 4, 5, 2, 2, 3],
      HIGH: [3, 3, 4, 5, 1, 5, 5, 3, 3, 4],
    },
    SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR: {
      LOW: [3, 3, 2, 3, 1, 5, 6, 4, 3, 5],
      MEDIUM: [3, 3, 3, 3, 2, 6, 6, 5, 3, 5],
      HIGH: [3, 3, 3, 3, 3, 6, 6, 5, 4, 6],
    },
    SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR: {
      LOW: [3, 3, 2, 3, 1, 4, 4, 2, 2, 2],
      MEDIUM: [3, 2, 3, 3, 2, 5, 5, 2, 2, 2],
      HIGH: [3, 2, 3, 3, 3, 4, 5, 2, 3, 2],
    },
    LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN: {
      LOW: [2, 1, 0, 0, 0, 2, 0, 1, 0, 0],
      MEDIUM: [3, 2, 1, 1, 0, 3, 0, 1, 1, 0],
      HIGH: [3, 2, 2, 1, 1, 3, 0, 1, 1, 0],
    },
    LAGERBAUTEN_MEHRSTOECKIGE: {
      LOW: [2, 1, 0, 0, 1, 2, 1, 1, 1, 1],
      MEDIUM: [4, 2, 1, 1, 1, 3, 1, 1, 1, 2],
      HIGH: [5, 2, 2, 1, 2, 3, 2, 1, 2, 2],
    },
    LAGERBAUTEN__VERTEILZENTRALEN: {
      LOW: [3, 1, 0, 1, 1, 2, 1, 1, 1, 1],
      MEDIUM: [4, 2, 1, 2, 1, 3, 1, 1, 1, 2],
      HIGH: [4, 2, 2, 2, 2, 3, 1, 2, 2, 2],
    },
    LAGER_PRODUKTION__PRODUKTION__INDUSTRIEHALLEN: {
      LOW: [3, 1, 1, 1, 1, 3, 1, 1, 1, 0],
      MEDIUM: [3, 2, 2, 1, 2, 3, 2, 1, 2, 1],
      HIGH: [3, 2, 3, 1, 2, 4, 2, 2, 2, 1],
    },
    LAGER_PRODUKTION__PRODUKTION__PRODUKTIONSBAUTEN: {
      LOW: [3, 2, 2, 3, 1, 4, 3, 1, 1, 2],
      MEDIUM: [3, 2, 2, 4, 2, 4, 5, 2, 2, 2],
      HIGH: [4, 3, 3, 5, 2, 5, 5, 2, 2, 2],
    },
    BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__LADENBAU: {
      LOW: [4, 3, 3, 3, 2, 3, 3, 2, 2, 2],
      MEDIUM: [4, 3, 3, 3, 3, 3, 4, 2, 2, 3],
      HIGH: [4, 3, 3, 4, 3, 4, 5, 3, 3, 3],
    },
    BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM: {
      LOW: [4, 3, 3, 3, 2, 4, 3, 2, 1, 1],
      MEDIUM: [4, 4, 3, 4, 2, 4, 3, 2, 2, 2],
      HIGH: [5, 4, 3, 5, 2, 4, 4, 2, 2, 2],
    },
    BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE__MARKT_MESSEHALLE: {
      LOW: [4, 4, 3, 1, 1, 1, 0, 1, 1, 1],
      MEDIUM: [4, 4, 3, 1, 3, 3, 2, 2, 2, 1],
      HIGH: [5, 4, 3, 2, 4, 5, 2, 3, 2, 2],
    },
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__VERWALTUNG: {
      LOW: [4, 2, 3, 4, 2, 5, 5, 2, 3, 3],
      MEDIUM: [5, 3, 4, 5, 3, 6, 5, 2, 4, 4],
      HIGH: [5, 4, 4, 4, 4, 6, 6, 3, 5, 5],
    },
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK: {
      LOW: [4, 3, 3, 4, 2, 5, 4, 2, 4, 3],
      MEDIUM: [5, 4, 4, 4, 3, 5, 5, 3, 4, 4],
      HIGH: [5, 5, 4, 4, 4, 6, 6, 3, 5, 5],
    },
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__GEMEINDEHAEUSER: {
      LOW: [4, 2, 3, 3, 2, 4, 5, 2, 3, 2],
      MEDIUM: [5, 3, 3, 4, 3, 5, 5, 2, 4, 4],
      HIGH: [5, 3, 4, 4, 4, 5, 6, 2, 5, 5],
    },
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__RATHAUS_STADTHAUS_STADTVERWALTUNG: {
      LOW: [5, 4, 3, 4, 3, 5, 5, 2, 4, 3],
      MEDIUM: [6, 4, 4, 4, 4, 6, 5, 2, 5, 4],
      HIGH: [7, 5, 4, 4, 4, 6, 6, 3, 5, 6],
    },
    BUERO_HANDELSBAUTEN__BVB__BUERO__EDELROHBAU: {
      LOW: [4, 2, 3, 3, 2, 4, 3, 2, 1, 1],
      MEDIUM: [4, 3, 3, 4, 3, 4, 4, 2, 2, 2],
      HIGH: [4, 3, 4, 5, 4, 5, 4, 2, 3, 3],
    },
    BUERO_HANDELSBAUTEN__BVB__BUERO__VOLLAUSBAU: {
      LOW: [4, 2, 3, 3, 2, 5, 4, 2, 3, 2],
      MEDIUM: [4, 3, 3, 4, 3, 5, 5, 2, 3, 4],
      HIGH: [4, 3, 4, 5, 4, 6, 5, 2, 4, 5],
    },
    JUSTIZBAUTEN__JUSTIZVOLLZUG__STRAFVOLLZUG: {
      LOW: [4, 3, 4, 2, 2, 4, 4, 4, 4, 3],
      MEDIUM: [4, 3, 5, 2, 3, 5, 4, 4, 4, 3],
      HIGH: [4, 3, 5, 2, 4, 5, 4, 5, 5, 4],
    },
    // GESUNDHEITSBAUTEN__TAGESHEIM_GESCHUTZE_WERKSTAETTE: {
    //   LOW: [3, 3, 3, 3, 1, 4, 3, 2, 3, 3],
    //   MEDIUM: [4, 3, 3, 4, 2, 4, 4, 3, 4, 4],
    //   HIGH: [4, 3, 4, 4, 3, 5, 5, 3, 5, 5],
    // },
    GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL: {
      LOW: [4, 3, 3, 4, 3, 6, 6, 5, 4, 4],
      MEDIUM: [4, 3, 3, 4, 4, 6, 6, 6, 5, 5],
      HIGH: [5, 3, 4, 4, 5, 6, 6, 6, 6, 6],
    },
    GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM: {
      LOW: [4, 3, 3, 3, 4, 5, 4, 4, 4, 3],
      MEDIUM: [5, 3, 3, 4, 4, 5, 5, 5, 5, 4],
      HIGH: [6, 3, 3, 4, 5, 6, 5, 5, 5, 5],
    },
    KULTUS__KIRCHGEMEINDEHAEUSER: {
      LOW: [4, 2, 3, 3, 2, 4, 3, 2, 3, 3],
      MEDIUM: [4, 3, 3, 3, 3, 4, 4, 3, 3, 4],
      HIGH: [4, 3, 3, 3, 4, 4, 5, 4, 4, 5],
    },
    KULTUR__AUSSTELLUNGEN: {
      LOW: [4, 3, 2, 3, 3, 4, 4, 2, 2, 2],
      MEDIUM: [4, 3, 3, 4, 4, 5, 5, 2, 3, 3],
      HIGH: [5, 3, 3, 4, 4, 6, 5, 3, 5, 5],
    },
    KULTUR__MUSEEN: {
      LOW: [5, 4, 2, 3, 3, 5, 4, 2, 3, 4],
      MEDIUM: [6, 5, 4, 3, 4, 6, 6, 3, 4, 5],
      HIGH: [6, 5, 6, 3, 4, 7, 6, 3, 5, 6],
    },
    KULTUR__KULTUR_GEMEINSCHAFTSZENTREN: {
      LOW: [3, 1, 3, 3, 2, 4, 3, 2, 1, 2],
      MEDIUM: [4, 2, 3, 4, 4, 4, 4, 3, 3, 3],
      HIGH: [4, 2, 3, 4, 5, 5, 5, 3, 5, 5],
    },
    KULTUR__SAAL: {
      LOW: [4, 2, 2, 4, 3, 4, 4, 2, 1, 2],
      MEDIUM: [5, 3, 2, 4, 4, 5, 5, 2, 3, 3],
      HIGH: [5, 3, 3, 4, 4, 5, 5, 2, 4, 4],
    },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__RESTUARATION: {
      LOW: [3, 4, 3, 5, 4, 5, 4, 5, 4, 4],
      MEDIUM: [4, 4, 3, 5, 5, 5, 5, 5, 5, 5],
      HIGH: [5, 4, 3, 5, 5, 6, 5, 5, 6, 6],
    },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__PERSONAL_RESTAURANT: {
      LOW: [3, 4, 3, 5, 3, 5, 4, 4, 3, 3],
      MEDIUM: [3, 4, 3, 5, 3, 5, 5, 4, 3, 4],
      HIGH: [4, 4, 3, 5, 4, 6, 5, 4, 4, 4],
    },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL: {
      LOW: [4, 3, 3, 3, 2, 4, 4, 4, 4, 3],
      MEDIUM: [4, 3, 3, 3, 4, 5, 5, 5, 4, 4],
      HIGH: [5, 4, 3, 4, 5, 5, 5, 6, 5, 5],
    },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE: {
      LOW: [2, 3, 3, 3, 2, 4, 4, 4, 3, 3],
      MEDIUM: [3, 4, 3, 3, 4, 4, 5, 4, 4, 4],
      HIGH: [4, 4, 3, 4, 5, 5, 5, 5, 5, 5],
    },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE: {
      LOW: [4, 2, 3, 3, 2, 4, 5, 3, 2, 4],
      MEDIUM: [4, 2, 3, 3, 3, 4, 5, 3, 3, 5],
      HIGH: [5, 3, 4, 3, 4, 5, 5, 4, 3, 5],
    },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN___GARDEROBENGEBAEUDE: {
      LOW: [3, 2, 3, 2, 2, 4, 4, 6, 2, 2],
      MEDIUM: [3, 2, 3, 2, 3, 4, 5, 6, 4, 4],
      HIGH: [3, 2, 4, 2, 4, 5, 5, 6, 5, 5],
    },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__HALLENBAD: {
      LOW: [4, 2, 3, 4, 2, 4, 7, 3, 2, 3],
      MEDIUM: [4, 2, 3, 5, 2, 4, 7, 3, 3, 4],
      HIGH: [5, 3, 4, 5, 3, 5, 7, 4, 5, 5],
    },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE: {
      LOW: [4, 0, 3, 0, 0, 1, 0, 0, 1, 1],
      MEDIUM: [4, 1, 3, 0, 1, 2, 0, 0, 1, 1],
      HIGH: [4, 2, 3, 0, 1, 2, 0, 0, 1, 1],
    },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE: {
      LOW: [4, 3, 3, 0, 2, 2, 1, 1, 1, 1],
      MEDIUM: [4, 3, 3, 0, 2, 2, 1, 1, 1, 1],
      HIGH: [4, 3, 3, 0, 3, 3, 2, 1, 2, 2],
    },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PARKHAUS: {
      LOW: [5, 5, 5, 0, 2, 3, 1, 1, 1, 2],
      MEDIUM: [5, 5, 6, 0, 2, 3, 2, 2, 2, 2],
      HIGH: [5, 5, 6, 0, 3, 3, 2, 2, 2, 2],
    },
    VERKEHRSBAUTEN__WERKHOEFE: {
      LOW: [4, 2, 3, 3, 2, 3, 2, 2, 2, 2],
      MEDIUM: [5, 2, 3, 3, 2, 3, 2, 2, 2, 2],
      HIGH: [5, 2, 3, 3, 3, 4, 3, 3, 2, 3],
    },
    VERKEHRSBAUTEN__ZOLL: {
      LOW: [5, 3, 3, 3, 2, 4, 5, 2, 3, 3],
      MEDIUM: [5, 3, 3, 3, 3, 5, 6, 3, 4, 4],
      HIGH: [5, 3, 3, 3, 4, 6, 6, 3, 4, 4],
    },
    // SCHUTZBAUTEN__ARMEE_LOGISTIK_ZEUGHAUS: {
    //   LOW: [5, 3, 3, 3, 2, 3, 3, 3, 2, 2],
    //   MEDIUM: [5, 3, 3, 3, 3, 4, 3, 4, 2, 3],
    //   HIGH: [5, 3, 3, 3, 3, 4, 3, 4, 3, 4],
    // },
    SCHUTZBAUTEN__FEUERWEHR: {
      LOW: [4, 2, 3, 4, 2, 5, 3, 2, 2, 2],
      MEDIUM: [5, 3, 3, 4, 2, 5, 4, 3, 3, 2],
      HIGH: [5, 3, 3, 4, 3, 4, 4, 3, 3, 3],
    },
    // SCHUTZBAUTEN__KASERNE_AUSBILDUNG__SCHULUNGSGEBAEUDE: {
    //   LOW: [3, 3, 3, 4, 3, 4, 3, 2, 3, 3],
    //   MEDIUM: [4, 3, 3, 4, 3, 4, 3, 2, 4, 4],
    //   HIGH: [5, 3, 4, 4, 4, 5, 3, 3, 4, 5],
    // },
    LANDWIRTSCHAFT__SCHEUNEN: {
      LOW: [1, 2, 1, 0, 0, 1, 0, 0, 0, 0],
      MEDIUM: [2, 2, 2, 1, 1, 1, 0, 0, 1, 0],
      HIGH: [2, 2, 2, 1, 1, 2, 0, 1, 1, 0],
    },
    // LANDWIRTSCHAFT_STALLUNGEN: {
    //   LOW: [1, 2, 1, 0, 1, 1, 0, 0, 0, 0],
    //   MEDIUM: [2, 2, 2, 1, 1, 2, 0, 1, 1, 0],
    //   HIGH: [2, 2, 2, 1, 2, 2, 0, 2, 1, 1],
    // },
    LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__STALLUNG: {
      LOW: [1, 2, 1, 0, 1, 1, 0, 1, 0, 0],
      MEDIUM: [2, 2, 2, 1, 1, 2, 0, 1, 1, 0],
      HIGH: [2, 2, 2, 1, 2, 2, 0, 2, 1, 0],
    },
    SCHULBAUTEN__BERUFSSCHULEN__GYM: {
      LOW: [3, 3, 3, 4, 3, 4, 5, 2, 3, 3],
      MEDIUM: [3, 3, 3, 4, 4, 5, 5, 2, 4, 4],
      HIGH: [4, 3, 4, 5, 4, 5, 5, 3, 4, 5],
    },
  };
}
