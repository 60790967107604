import { Injectable } from '@angular/core';
import { Logger } from '@shared';
import { SliderValue } from '@generated/generatedEntities';
const log = new Logger('SliderIdentMappingService');
@Injectable({
  providedIn: 'root',
})
export class SliderIdentMappingService {
  public valuesMore = [0, 1, 2, 3, 4, 5, 6, 7];
  public valuesLess = [1, 3, 5];

  public getSliderIdentByMore(value: any): any {
    // log.warn(value, this.valuesMore[Math.round(value)]);
    return this.valuesMore[Math.round(value)];
  }

  public getSliderIdentByLess(value: any): any {
    // log.warn(value,this.valuesLess[Math.round(value)])
    return this.valuesLess[Math.round(value)];
  }

  public getSliderValueIndexByMore(value: SliderValue): number {
    let val = Number(value.slice(-1)) - 1;
    return this.valuesMore.indexOf(val);
  }

  public getSliderValueIndexByLess(value: SliderValue): number {
    let val = Number(value.slice(-1)) - 1;
    return this.valuesLess.indexOf(val);
  }

  public getValuesByLess(): number[] {
    return this.valuesLess;
  }

  public getValuesByMore(): number[] {
    return this.valuesMore;
  }

  public extractValue(value: any, isMaxValue: any): number {
    if (isMaxValue) {
      return Number(value.slice(-1));
    } else {
      let val = Number(value.slice(-1)) - 1;
      return this.valuesLess.indexOf(val) + 1;
    }
  }
}
