import { Injectable } from '@angular/core';
import { HeatingType } from '@generated/generatedEntities';

@Injectable({
  providedIn: 'root',
})
export class CompactHeatingTypeSelectService {
  BASE_GROUP = 'Wärmeerzeugung';
  SHARED_GROUP = 'Bezug';
  EXTRA_GROUP = 'Zusätzlich';

  NO_HEATING: { group: string; value: HeatingType } = { group: this.BASE_GROUP, value: 'NO_HEATING' };
  CONTRACTING: { group: string; value: HeatingType } = { group: this.BASE_GROUP, value: 'CONTRACTING' };
  DISTRICT_HEATING: { group: string; value: HeatingType } = { group: this.BASE_GROUP, value: 'DISTRICT_HEATING' };
  NATURAL_GAS: { group: string; value: HeatingType } = { group: this.BASE_GROUP, value: 'NATURAL_GAS' };
  OIL: { group: string; value: HeatingType } = { group: this.BASE_GROUP, value: 'OIL' };
  PELLET: { group: string; value: HeatingType } = { group: this.BASE_GROUP, value: 'PELLET' };
  WOOD_CHIPS: { group: string; value: HeatingType } = { group: this.BASE_GROUP, value: 'WOOD_CHIPS' };
  HEAT_PUMP_AIR: { group: string; value: HeatingType } = { group: this.BASE_GROUP, value: 'HEAT_PUMP_AIR' };
  HEAT_PUMP_GEOTHERMAL: { group: string; value: HeatingType } = {
    group: this.BASE_GROUP,
    value: 'HEAT_PUMP_GEOTHERMAL',
  };
  HEAT_PUMP_GROUND_COLLECTOR: { group: string; value: HeatingType } = {
    group: this.BASE_GROUP,
    value: 'HEAT_PUMP_GROUND_COLLECTOR',
  };
  HEAT_PUMP_GROUND_WATER: { group: string; value: HeatingType } = {
    group: this.BASE_GROUP,
    value: 'HEAT_PUMP_GROUND_WATER',
  };
  OTHER: { group: string; value: HeatingType } = { group: this.BASE_GROUP, value: 'OTHER' };
  SOLAR_COLLECTORS_THERMAL: { group: string; value: HeatingType } = {
    group: this.EXTRA_GROUP,
    value: 'SOLAR_COLLECTORS_THERMAL',
  };

  SHARED_ANNEX: { group: string; value: HeatingType } = { group: this.SHARED_GROUP, value: 'SHARED_ANNEX' };
  SHARED_ADDITION_STORY: { group: string; value: HeatingType } = {
    group: this.SHARED_GROUP,
    value: 'SHARED_ADDITION_STORY',
  };
  SHARED_OVERHAUL: { group: string; value: HeatingType } = { group: this.SHARED_GROUP, value: 'SHARED_OVERHAUL' };

  baseHeatingTypes = [
    this.NO_HEATING,
    this.CONTRACTING,
    this.DISTRICT_HEATING,
    this.NATURAL_GAS,
    this.OIL,
    this.PELLET,
    this.WOOD_CHIPS,
    this.HEAT_PUMP_AIR,
    this.HEAT_PUMP_GEOTHERMAL,
    this.HEAT_PUMP_GROUND_COLLECTOR,
    this.HEAT_PUMP_GROUND_WATER,
  ];

  extras = [this.SOLAR_COLLECTORS_THERMAL];

  shared = [this.SHARED_ANNEX, this.SHARED_ADDITION_STORY, this.SHARED_OVERHAUL];

  default = this.baseHeatingTypes;
  extra = this.extras;
  rebuildIs = this.baseHeatingTypes.concat([this.OTHER]);
  rebuildTarget = this.shared.concat(this.baseHeatingTypes);
}
