import { inject, Injectable } from '@angular/core';
import { Logger } from '@shared';
import { RealEstateUsageEmbeddable } from '@generated/generatedEntities';
import { HttpClient } from '@angular/common/http';
import { EMPTY, of, take } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@env/environment';

const log = new Logger('RoofTypePresetService');
@Injectable({
  providedIn: 'root',
})
export class RoofTypePresetService {
  private http = inject(HttpClient);

  public presetRoofType2(usages: RealEstateUsageEmbeddable[], realEstateContainer: any) {
    var gmdNr = realEstateContainer.location.gmdNr;
    var roofPreset: unknown = undefined;

    let result$ = of(realEstateContainer);
    result$ = result$.pipe(
      switchMap(() =>
        this.http.post(
          `${environment.serverUrl}/api/preset/roof`,
          { usages, locationGmdNr: gmdNr },
          { responseType: 'text' },
        ),
      ),
      take(1),
      tap((value) => {
        roofPreset = value;
      }),
      catchError(() => {
        return EMPTY;
      }),
    );

    return result$.pipe(
      tap((value) => {
        realEstateContainer.quality.roofTypes = [roofPreset];
      }),
      map(() => realEstateContainer),
    );
  }
}
