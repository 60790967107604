import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Logger } from '@app/@shared';
import { SharedControllerFnService } from '@app/core/services/shared/shared-controller-fn.service';
import { environment } from '@env/environment';
import { NullableRealEstateDTO } from '@generated/extended';
import {
  AdditionEntryDTO,
  Area416Dto,
  OutRealEstateDTO,
  RealEstateDTO,
  WithdrawalProductType,
  WithdrawDTO,
} from '@generated/generatedEntities';
import { BehaviorSubject, map, Observable, of, take } from 'rxjs';

const log = new Logger('SmallBuildingRestService');

@Injectable({
  providedIn: 'root',
})
export class SmallBuildingRestService {
  private http = inject(HttpClient);
  private SharedControllerFnService = inject(SharedControllerFnService);
  smallBuilding = {};
  valueClassPreset = 0;
  q: any;
  private realEstate$ = new BehaviorSubject<RealEstateDTO>({} as RealEstateDTO);
  isDataLoadedResponse$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  private sliderValue$ = new BehaviorSubject<any>(null);

  public realEstateContainer: RealEstateDTO | null = null;
  constructor() {
    log.warn('SmallBuildingRestService running');
  }

  getRealEstateDataObservable(): Observable<RealEstateDTO> {
    return this.realEstate$.asObservable();
  }

  getByIdCached(id: any) {
    this.smallBuilding = this.getRealEstateById({ id: null });
  }

  getByIdForceLoad(id: any) {
    this.smallBuilding = this.getRealEstateById({ id: id });
    // console.log(this.smallBuilding.$promise);
    // return this.smallBuilding.$promise;
  }

  getCalculateResponse(): Observable<any | null> {
    return this.isDataLoadedResponse$.asObservable();
  }

  getSliderResponse(): Observable<any | null> {
    return this.sliderValue$.asObservable();
  }

  setSliderResponse(sliderVal: any) {
    this.sliderValue$.next(sliderVal);
  }

  tryCalculation(realEstat: any): Observable<WithdrawDTO> {
    // console.log('this.testEstimations ==> ', realEstat);
    // localStorage.setItem('my_data', JSON.stringify(realEstat));
    return this.estimateBuildingMetrics(realEstat);
  }

  getRealEstateById(id: any): Observable<OutRealEstateDTO> {
    return this.http.get<OutRealEstateDTO>(`${environment.serverUrl}/api/smallBuilding/${id}`);
  }

  // testEstimation: NullableRealEstateDTO = {
  //   usages: [{ type: 'WOHNBAUTEN__MFH', standard: 'MEDIUM', percentage: null }],
  //   geometry: {
  //     totalFloorArea416: 900,
  //     totalVolume416: 2800,
  //     totalVolume116: null,
  //     builtUpArea: 200,
  //     areaBuilding416: null,
  //     ratioVolumeUnderTerrain: 25,
  //     buildings: 1,
  //     overgroundFloors: 3,
  //     undergroundFloors: 1,
  //     elevator: { inclined: null, vertical: 1 },
  //     elevatorInside: { inclined: null, vertical: null },
  //     elevatorOutside: { inclined: null, vertical: null },
  //     lifts: null,
  //     undergroundParkingSpaces: null,
  //     dimensionSheet: null,
  //   },
  //   quality: {
  //     qualitaetGrundstueck: 'WK3',
  //     qualitaetGrundstueckBackend: 'WK3',
  //     qualitaetGrundstueckInterval: [1, 1.46, 2.46, 3.1, 3.82, 5.12, 6],
  //     qualitaetvorbereitungsarbeiten: 'WK3',
  //     qualitaetvorbereitungsarbeitenBackend: 'WK3',
  //     qualitaetvorbereitungsarbeitenInterval: [0, 0.45, 1.76, 2.74, 3.85, 5.45, 6],
  //     qualitaetVorfertigung: 'WK5',
  //     qualitaetVorfertigungBackend: 'WK5',
  //     qualitaetVorfertigungInterval: null,
  //     qualitaetDerGebaeudeform2: 'WK4',
  //     qualitaetDerGebaeudeform2Backend: 'WK4',
  //     qualitaetDerGebaeudeform2Interval: [1.48, 1.48, 2.65, 3.55, 4.35, 5.61, 6],
  //     materialType: null,
  //     facadeType: 'AUSSENDAEMMUNG_PUTZ_EDEL',
  //     fensterAnteil: 'WK3',
  //     fensterAnteilBackend: 'WK3',
  //     fensterAnteilInterval: [1.56, 1.56, 2.53, 3.15, 3.87, 5.16, 5.16],
  //     qualitaetElektroAnlage: 'WK3',
  //     qualitaetElektroAnlageBackend: 'WK3',
  //     qualitaetElektroAnlageInterval: [1.57, 1.57, 2.44, 3, 3.56, 4.53, 6],
  //     qualitaetHeizungsanlage: 'WK4',
  //     qualitaetHeizungsanlageBackend: 'WK4',
  //     qualitaetHeizungsanlageInterval: [0.95, 0.95, 2.12, 3.23, 4.23, 5.96, 5.96],
  //     qualitaetSanitaeranlage: 'WK4',
  //     qualitaetSanitaeranlageBackend: 'WK4',
  //     qualitaetSanitaeranlageInterval: [2, 2.53, 3.56, 4.23, 4.89, 5.79, 6],
  //     qualitaetAusbau1: 'WK3',
  //     qualitaetAusbau1Backend: 'WK3',
  //     qualitaetAusbau1Interval: [0.94, 0.94, 1.99, 2.72, 3.5, 5.07, 6],
  //     qualitaetAusbau2: 'WK3',
  //     qualitaetAusbau2Backend: 'WK3',
  //     qualitaetAusbau2Interval: [0.89, 0.89, 1.89, 2.58, 3.36, 4.88, 6],
  //     qualitaetUmgebung: 'WK3',
  //     qualitaetUmgebungBackend: 'WK3',
  //     qualitaetUmgebungInterval: [1.52, 1.52, 2.67, 3.44, 4.36, 5.81, 6],
  //     energyStandard: null,
  //     minergieStandard: 'NONE',
  //     hasVentilation: null,
  //     ventilationType: null,
  //     hasConditioning: null,
  //     chillerType: null,
  //     roofType: null,
  //     roofTypes: ['FLATROOF'],
  //     pv: false,
  //   },
  //   location: {
  //     place: 'Lausanne',
  //     zipCode: '1006',
  //     street: 'estimation street',
  //     concat: '1006 Lausanne',
  //     gmdNr: 5586,
  //   },
  //   metaData: {
  //     userLogin: 'admin',
  //     parentRealEstateId: null,
  //     lastModifiedDate: '2022-08-25T05:02:21.620714Z',
  //     name: 'test estimation',
  //     withdrawalProductType: 'BUILDING_COSTS_AND_DATES',
  //     tasks: ['BUILDING_COSTS_AND_DATES'],
  //     numEstimations: 0,
  //     mainImageId: null,
  //     totalCosts: null,
  //     remark: null,
  //     favorite: 'NO_FAVORITE',
  //   },
  //   additions: [{}],
  //   pom: { securityType: null },
  //   agv: {
  //     externalVrNumber: null,
  //     standard: null,
  //     prefabrication: null,
  //     canopy: null,
  //     location: null,
  //     closed: null,
  //     buildStyle: null,
  //     roof: null,
  //     heating: null,
  //     wk: null,
  //     usages: [],
  //     pValue: [],
  //     totalArea416: null,
  //     pictures: [],
  //   },
  //   constructionYear: null,
  //   replacementOriginalRealEstateId: null,
  // };

  checkRealEstate(realEstate: NullableRealEstateDTO | RealEstateDTO): Observable<string> {
    return this.http.post<string>(
      `${environment.serverUrl}/api/withdrawal/small_building/check_realestate`,
      realEstate,
    );
  }

  estimateBuildingMetrics(realEstate: NullableRealEstateDTO | RealEstateDTO): Observable<WithdrawDTO> {
    return this.http.post<WithdrawDTO>(`${environment.serverUrl}/api/withdrawal/small_building/estimate`, realEstate);
  }

  getValueClassPreset(realEstate: NullableRealEstateDTO | RealEstateDTO): Observable<number> {
    console.warn('slider values', realEstate);
    return this.http.post<number>(`${environment.serverUrl}/api/smallBuilding/value_class_preset`, realEstate);
    // .pipe(
    //   map((value) => {
    //     if (value != null) {
    //       this.sliderValue$.next(value);
    //     }
    //   })
    // );
  }

  /* have to check for these validations before doing the OG calculations :: => STARTS*/
  tryServerValidation(realEstate: any): Observable<string> {
    //before this is called clean DTO then do client side validation
    //then this is called - if all good then call calculate.
    var realEstateContainerCopy = JSON.parse(JSON.stringify(realEstate)); // MAKING A COPY OF THE REALESTATE OBJ
    this.SharedControllerFnService.cleanDto(realEstateContainerCopy); // CLEANING THE DTO
    // this.testEstimation!.metaData!.name = `test estimation ${this.genrateRandomNumber(999, 9999)}`;
    return this.checkRealEstate(realEstateContainerCopy);
  }

  getEmptyRealEstate(wtp?: WithdrawalProductType): Observable<RealEstateDTO> {
    return this.http
      .post<RealEstateDTO>(`${environment.serverUrl}/api/smallBuilding`, {
        parentRealEstateId: null,
        name: null,
        withdrawalProductType: wtp == null ? 'SMALL_BUILDING_AGV' : wtp,
      })
      .pipe(
        map((value, index) => {
          this.realEstateContainer = value;
          this.isDataLoadedResponse$.next(false);
          return this.realEstateContainer;
        }),
        // map((value: RealEstateDTO) => {
        //   // this.realEstateContainer = value;
        // })
      );
  }

  endsWith(str: any, suffix: any) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  postProcessForEditRecordById(realEstate: any): Observable<RealEstateDTO> {
    if (realEstate.id == null || realEstate.id == undefined) {
      const NewRealEstateDTO = {} as RealEstateDTO;
      log.error('returning empty obs - no id set');
      // if (NewRealEstateDTO.additions.length === 0) {
      NewRealEstateDTO.additions = [];
      NewRealEstateDTO.additions.push({} as AdditionEntryDTO);
      // }
      return of({} as RealEstateDTO);
    }
    realEstate.metaData.parentRealEstateId = realEstate.metaData.parentRealEstateId
      ? realEstate.metaData.parentRealEstateId
      : realEstate.id;
    if (this.endsWith(realEstate.agv.externalVrNumber, ')')) {
      var brBegin = realEstate.agv.externalVrNumber.lastIndexOf('(');
      if (brBegin >= 0) {
        var txtBetweenBr = realEstate.agv.externalVrNumber.substring(
          brBegin + 1,
          realEstate.agv.externalVrNumber.length - 1,
        );
        var parsedNumBetweenBr = parseInt(txtBetweenBr);
        if (parsedNumBetweenBr) {
          realEstate.agv.externalVrNumber =
            realEstate.agv.externalVrNumber.substring(0, brBegin - 1) +
            ' (' +
            (realEstate.metaData.numEstimations + 1) +
            ')';
        }
      }
    } else {
      realEstate.agv.externalVrNumber =
        realEstate.agv.externalVrNumber + ' (' + (realEstate.metaData.numEstimations + 1) + ')';
    }
    realEstate.id = null;
    if (realEstate.additions.length === 0) {
      realEstate.additions = [];
      realEstate.additions.push({});
    }
    if (realEstate.investments.length === 0) {
      realEstate.investments = [];
      realEstate.investments.push({});
    }
    this.realEstate$.next(realEstate);
    this.realEstateContainer = realEstate;
    return this.getRealEstateDataObservable().pipe(take(1));
  }

  estimateArea(realEstate: any): Observable<Area416Dto> {
    return this.http.post<Area416Dto>(
      `${environment.serverUrl}/api/public/estimate-real-estate-property/small_building/area_by_volume`,
      realEstate,
    );
  }
}
