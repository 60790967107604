import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CompactChillerTypeSelectService {
  baseGroup = 'Lüftung-, Kälteanlagen';
  sharedGroup = 'Bezug';

  NO = { group: this.baseGroup, value: 'NO' };
  YES = { group: this.baseGroup, value: 'YES' };

  SHARED_ANNEX = { group: this.sharedGroup, value: 'SHARED_ANNEX' };
  SHARED_ADDITION_STORY = { group: this.sharedGroup, value: 'SHARED_ADDITION_STORY' };
  SHARED_OVERHAUL = { group: this.sharedGroup, value: 'SHARED_OVERHAUL' };

  baseVentilationTypes = [this.NO, this.YES];

  shared = [this.SHARED_ANNEX, this.SHARED_ADDITION_STORY, this.SHARED_OVERHAUL];

  defaultData = this.baseVentilationTypes;
  rebuildIs = this.baseVentilationTypes;
  rebuildTarget = this.shared.concat(this.baseVentilationTypes);
}
