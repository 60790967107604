import { inject, Injectable } from '@angular/core';
import { Logger } from '@shared';
import { MinergieStandard, RealEstateDTO, RealEstateUsageEmbeddable } from '@generated/generatedEntities';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { EMPTY, take } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

const log = new Logger('MinergieTypePresetService');
@Injectable({
  providedIn: 'root',
})
export class MinergieTypePresetService {
  private http = inject(HttpClient);

  public presetMinergieType2(usages: RealEstateUsageEmbeddable[], realEstate: RealEstateDTO) {
    const constructionYear = realEstate.constructionYear || realEstate.lastRebuildYear;
    return this.http
      .post(`${environment.serverUrl}/api/preset/minergie-type`, { usages, constructionYear }, { responseType: 'text' })
      .pipe(
        take(1),
        tap((minergiePreset) => {
          const year = realEstate.constructionYear || realEstate.lastRebuildYear;
          if (year !== null && year !== undefined && year < 2000 && minergiePreset != 'NONE') {
            realEstate.quality.minergieStandard = 'NONE';
          } else if (minergiePreset != null) {
            realEstate.quality.minergieStandard = minergiePreset as MinergieStandard;
          }
        }),
        map(() => realEstate),
        catchError(() => {
          return EMPTY;
        }),
      );
  }
}
