import { inject, Injectable } from '@angular/core';
import { Logger } from '@shared';
import { RealEstateUsageEmbeddable } from '@generated/generatedEntities';
import { environment } from '@env/environment';
import { EMPTY, map, take, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

const log = new Logger('FacadePresetService');
@Injectable({
  providedIn: 'root',
})
export class FacadePresetService {
  private http = inject(HttpClient);

  presetFacade2(usages: RealEstateUsageEmbeddable[], realEstateContainer: any) {
    return this.http.post(`${environment.serverUrl}/api/preset/facade`, usages, { responseType: 'text' }).pipe(
      take(1),
      tap((facadePreset) => {
        realEstateContainer.quality.facadeType = facadePreset;
        if (realEstateContainer.metaData.tasks.includes('LCA')) {
          realEstateContainer.quality.facadeTypes[0].facadeType = facadePreset;
        }
      }),
      map(() => realEstateContainer),
      catchError(() => {
        return EMPTY;
      }),
    );
  }
}
