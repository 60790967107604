import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject } from 'rxjs';

export interface GlobalToast {
  reference: string;
  element: any | string | number;
}

export interface TranslationPopup {
  translation: string;
  location: string;
  showToast: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GlobaltoastService {
  private _translocoService = inject(TranslocoService);
  private route = inject(Router);
  public toastObservable$ = new BehaviorSubject<GlobalToast>({} as GlobalToast);
  public translation$ = new BehaviorSubject<TranslationPopup>({} as TranslationPopup);
  public showSliderPresetTooltip$ = new BehaviorSubject<boolean>(false);
  ToastTime: number = 11000; //11 seconds

  showSmallBuildingTotalAreaWarning(realEstateContainer: any) {
    // first destroy element
    if (realEstateContainer && realEstateContainer.agv && realEstateContainer.agv.totalArea416) {
      // var element = '#' + _escapeInSelector('realEstateContainer.agv.totalArea416.input');
      // if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass('tooltipstered')) {
      //   $(element).tooltipster('close');
      // }
      let element = 'realEstate.agv.totalArea416';
      if (realEstateContainer.agv.totalArea416 > 100) {
        var translation = this.getShowSmallBuildingTotalAreaWarningText();
        var toast = '<div>' + '<label>' + translation + '</label>' + '</div>';

        this.showToast(element, toast);
      }
    }
  }

  getShowSmallBuildingTotalAreaWarningText() {
    return this._translocoService.translate('toasts.smallBuilding.showSmallBuildingTotalAreaWarning.tobig');
  }

  getSmallBuildingHeatingDisabledWarningText() {
    return this._translocoService.translate('toasts.smallBuilding.showSmallBuildingHeatingDisabledWarning');
  }

  showSmallBuildingHeatingDisabledWarningText() {
    // var element = '#' + _escapeInSelector('realEstateContainer.agv.heating.drop2down-toggle');
    // if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass('tooltipstered')) {
    //     $(element).tooltipster('close');
    // }
    let element = 'realEstate.agv.heating';
    let translation = this.getSmallBuildingHeatingDisabledWarningText();
    let toast = '<div>' + '<label>' + translation + '</label>' + '</div>';
    this.showToast(element, toast);
  }

  /**
   * Showing a Toast that all preset-able values are set. This is only used in the Building Costs.
   * @param elementId
   */
  showPresetChangedToast(elementId: any) {
    // var element = '#' + _escapeInSelector(elementId);
    let state = this.route.url;
    let element = elementId;
    // if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass('tooltipstered')) {
    //     $(element).tooltipster('close');
    // }
    if (state.includes('edit-record') || state.includes('edit')) {
      let editText = 'modelrealEstate.realEstate.general.process-form.edit-record.generalPreset';
      // $translate(editText).then(function(translation) {
      //     showToast(element, translation, { hasArrow: false, width: 350 });
      // }).catch(function(e) {
      //     console.warn(e);
      // });
      this.showToast(element, editText);
    }
    if (state.includes('input') || state.includes('input')) {
      let inputText = 'modelrealEstate.realEstate.general.process-form.input.generalPreset';
      // $translate(inputText).then(function(translation) {
      //     showToast(element, translation, { hasArrow: false, width: 350 });
      // }).catch(function(e) {
      //     console.warn(e);
      // });
      this.showToast(element, inputText);
    }
  }

  showPresetFacade(validationId: any) {
    let state = this.route.url;
    let element = validationId;

    if (state.includes('edit-record') || state.includes('edit')) {
      let editText = 'modelrealEstate.realEstate.general.toasts.edit.facadePreset';
      this.showToast(element, editText);
    }
    if (state.includes('input') || state.includes('input')) {
      let inputText = 'modelrealEstate.realEstate.general.toasts.input.facadePreset';
      this.showToast(element, inputText);
    }
  }

  /**
   * show toast, similar to usage change
   * @param element
   * @param text
   */
  showToast(element: string | any, text: string) {
    if (text !== '') {
      this.translation$.next({
        translation: this._translocoService.translate(text),
        location: element,
        showToast: true,
      } as TranslationPopup);
    } else {
      this.translation$.next({
        translation: '',
        location: element,
        showToast: false,
      } as TranslationPopup);
    }
  }
}
