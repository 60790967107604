import { LocationStrategy } from '@angular/common';
import { Injectable, inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RouteUtilService {
  private url = inject(LocationStrategy);

  isRouteMap(pathString: string) {
    return this.url.path().includes(pathString);
  }
}
