import { produce } from 'immer';

/**
 * Helper method that wraps a reducer with the Immer `produce` method
 * Kudos to Alex Okrushko {@link https://lookout.dev/rules/simple-immer-base-function-to-be-used-in-ngrx-store-or-componentstore-for-transforming-data-%22mutably%22}
 */
function immerReducer(callback) {
  return (state, next) => {
    return produce(state, draft => callback(draft, next));
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { immerReducer };
