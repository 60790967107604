import { ComponentMeasureDTO, MeasureSliderValue } from '@generated/generatedEntities';

export class ComponentMeasure implements ComponentMeasureDTO {
  constructor() {}

  name?: string;
  measure?: MeasureSliderValue;
  measureBackend?: MeasureSliderValue;
  measureClientOverwrite?: MeasureSliderValue;
  measureUserInput?: MeasureSliderValue;
  interventionGrade?: number;
  interventionGradeBackend?: number;
  interventionGradeSigmaBackend?: number;
  interventionGradeUserInput?: number;
  interventionGradeQuantity?: number;
  interventionGradeQuantityBackend?: number;
  interventionGradeQuantityUserInput?: number;
  totalInterventionGrade?: number;
  totalInterventionGradePercentFormatted?: string;
  interval?: number[];
  intervalGap?: any;
  interventionGradePlaceholder: any;
  interventionGradeQuantityPlaceholder: any;

  isInput(input: any) {
    var test = input || input === 0;
    //due to some values are set to "NaN" causes issues on the backend -> cant convert to Number.
    if (test === 'NaN') {
      return null;
    }
    return test;
  }

  //   ComponentMeasures() {
  //     this.name = undefined;

  //     this.measure = undefined;
  //     this.measureBackend = undefined;
  //     this.measureClientOverwrite = undefined;
  //     this.measureUserInput = undefined;

  //     this.interventionGrade = undefined;
  //     this.interventionGradePlaceholder = undefined;
  //     this.interventionGradeBackend = undefined;
  //     this.interventionGradeUserInput = undefined;

  //     this.interventionGradeSigmaBackend = undefined;

  //     this.interventionGradeQuantity = undefined;
  //     this.interventionGradeQuantityPlaceholder = undefined;
  //     this.interventionGradeQuantityBackend = undefined;
  //     this.interventionGradeQuantityUserInput = undefined;

  //     this.interval = undefined;
  //     this.intervalGap = undefined;
  //   }

  updateMeasure() {
    var measure = this.measure;
    if (this.isInput(this.measureBackend)) measure = this.measureBackend;
    if (this.isInput(this.measureClientOverwrite)) measure = this.measureClientOverwrite;
    if (this.isInput(this.measureUserInput)) measure = this.measureUserInput;
    var hasChanged = measure !== this.measure;
    this.measure = measure;
    return hasChanged;
  }

  setMeasureBackend(measure: MeasureSliderValue, interval?: any, intervalGap?: any) {
    this.measureBackend = measure;
    if (interval && Array.isArray(interval)) {
      this.interval = interval;
    }
    if (intervalGap && Array.isArray(intervalGap)) {
      this.intervalGap = intervalGap;
    }
    return this.updateMeasure();
  }

  /**
   * internal overwrite via javascript logic.
   * @param measure
   * @returns {boolean}
   */
  setMeasureClientOverwrite(measure: any) {
    this.measureClientOverwrite = measure;
    return this.updateMeasure();
  }

  setMeasureUserInput(measure: any) {
    this.measureUserInput = measure;
    if (measure !== null) {
      this.setInterventionGradeUserInput(null);
    }
    return this.updateMeasure();
  }

  updateInterventionGrade() {
    var interventionGrade = this.interventionGrade;
    if (this.isInput(this.interventionGradePlaceholder)) interventionGrade = this.interventionGradePlaceholder;
    if (this.isInput(this.interventionGradeBackend)) interventionGrade = this.interventionGradeBackend;
    if (this.isInput(this.interventionGradeUserInput)) interventionGrade = this.interventionGradeUserInput;
    var hasChanged = interventionGrade !== this.interventionGrade;
    if (interventionGrade == null) {
      // console.error(`interventionGrade should not be null ${this.name}`);
    }
    this.interventionGrade = interventionGrade;
    return hasChanged;
  }

  setInterventionGradeBackend(interventionGrade: number) {
    if (Number.isFinite(interventionGrade)) {
      this.interventionGradeBackend = interventionGrade;
      this.interventionGradePlaceholder = '' + Math.round(interventionGrade);
      return this.updateInterventionGrade();
    } else {
      console.warn('setInterventionGradeBackend is not a number');
      return this.updateInterventionGrade();
    }
  }

  setInterventionGradeSigmaBackend(interventionGradeSigma: number) {
    this.interventionGradeSigmaBackend = interventionGradeSigma;
  }

  setInterventionGradeUserInput(interventionGrade: any) {
    this.interventionGradeUserInput = interventionGrade;
    if (interventionGrade != null && this.isInput(interventionGrade)) {
      var measure = this.getMeasureValuesBasedOnInterventionGrade(interventionGrade);
      if (measure !== this.measureUserInput) {
        this.setMeasureClientOverwrite(this.getMeasureValuesBasedOnInterventionGrade(interventionGrade));
        this.setMeasureUserInput(null);
      }
    } else {
      this.setMeasureClientOverwrite(null);
    }
    return this.updateInterventionGrade();
  }

  updateInterventionGradeQuantity() {
    var interventionGradeQ = this.interventionGradeQuantity;
    if (this.isInput(this.interventionGradeQuantityPlaceholder))
      interventionGradeQ = this.interventionGradeQuantityPlaceholder;
    if (this.isInput(this.interventionGradeQuantityBackend)) interventionGradeQ = this.interventionGradeQuantityBackend;
    if (this.isInput(this.interventionGradeQuantityUserInput))
      interventionGradeQ = this.interventionGradeQuantityUserInput;
    var hasChanged = interventionGradeQ !== this.interventionGradeQuantity;
    this.interventionGradeQuantity = interventionGradeQ;
    return hasChanged;
  }

  setInterventionGradeQuantityBackend(interventionGradeQuantity: number | undefined | null) {
    //should be better that we on null input we display empty?
    if (typeof interventionGradeQuantity === 'number') {
      this.interventionGradeQuantityBackend = interventionGradeQuantity;
      this.interventionGradeQuantityPlaceholder = '' + Math.round(interventionGradeQuantity);
    } else {
      this.interventionGradeQuantityBackend = undefined;
      this.interventionGradeQuantityPlaceholder = '';
    }
    return this.updateInterventionGradeQuantity();
  }

  setInterventionGradeQuantityPlaceholder(interventionGradeQuantityPlaceholder: any) {
    this.interventionGradeQuantityPlaceholder = '' + Math.round(interventionGradeQuantityPlaceholder);
    return this.updateInterventionGradeQuantity();
  }

  setInterventionGradeQuantityUserInput(interventionGradeQuantity: number | undefined | null) {
    if (typeof interventionGradeQuantity === 'number' || Number.isFinite(interventionGradeQuantity)) {
      this.interventionGradeQuantityUserInput = Number(interventionGradeQuantity);
    } else {
      // console.debug(`Component Measure ${this.name}: ${interventionGradeQuantity}`);
    }
    return this.updateInterventionGradeQuantity();
  }

  getMeasureValuesBasedOnInterventionGrade(interventionGrade: number): MeasureSliderValue {
    var measure;
    if (interventionGrade <= 10) measure = 'MK1';
    else if (interventionGrade <= 25) measure = 'MK2';
    else if (interventionGrade <= 40) measure = 'MK3';
    else if (interventionGrade <= 80) measure = 'MK4';
    else measure = 'MK5';
    return measure as MeasureSliderValue;
  }

  getIntervalFromMeasure(mk: any) {
    if (mk <= 'MK1') return [0, 10];
    else if (mk <= 'MK2') return [10, 25];
    else if (mk <= 'MK3') return [25, 40];
    else if (mk <= 'MK4') return [40, 80];
    return [80, 100];
  }
}
