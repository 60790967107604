import { inject, Injectable } from '@angular/core';
import { RealEstateService } from '@app/core/services/realEstate/real-estate.service';
import { ValidationDataService } from '@app/core/services/validation/validation-data.service';
import { RealEstateDTO, RealEstateUsageEmbeddable } from 'src/generated/generatedEntities';
import { RoomHeightLimitsWarningService } from '../room-height/room-height-limits-warning.service';
import { SliderIdentMappingService } from '../slider/slider-ident-mapping.service';
import { SliderPresetService } from '../slider/slider-preset.service';
import { FacadePresetService } from './facade-preset.service';
import { MinergieEcoPresetService } from './minergie-eco-preset.service';
import { MinergieTypePresetService } from './minergie-type-preset.service';
import { RoofTypePresetService } from './roof-type-preset.service';
import { RouteUtilService } from '@app/calculators/components/util/route-util.service';
import { EMPTY, Observable, of, Subscription, take } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PresetWrapperService {
  private realEstateService = inject(RealEstateService);
  private sliderPresetService = inject(SliderPresetService);
  private facadePresetService = inject(FacadePresetService);
  private minergieTypePresetService = inject(MinergieTypePresetService);
  private minergieEcoPresetService = inject(MinergieEcoPresetService);
  private roofTypePresetService = inject(RoofTypePresetService);
  private sliderIdentMappingService = inject(SliderIdentMappingService);
  private validationDataService = inject(ValidationDataService);
  private roomHeightLimitsWarningService = inject(RoomHeightLimitsWarningService);
  private _routeUtil = inject(RouteUtilService);
  realEstate: RealEstateDTO;
  isPresetSliderModelValuesEnabled: boolean = true;
  realEstateContainer: any;

  private sliderPresetSubsc: Subscription | null = null;

  constructor() {
    if (this.realEstateService.realEstateContainer != null) {
      this.realEstate = this.realEstateService.realEstateContainer;
    } else {
      this.realEstate = {} as RealEstateDTO;
    }
  }

  /**
   * Set preset values for sliders if they are not set yet. Otherwise, backend cant calculate.
   * @param realEstate
   */
  setSliderValues(realEstate: RealEstateDTO) {
    this.realEstate = realEstate;
    if (this.sliderPresetSubsc != null) {
      this.sliderPresetSubsc.unsubscribe();
      this.sliderPresetSubsc = null;
    }
    this.sliderPresetSubsc = this.sliderPresetService
      .requestPresetEvent2(this.realEstate)
      .pipe(untilDestroyed(this))
      .subscribe({});
  }

  realEstateUsages2(usages: RealEstateUsageEmbeddable[], realEstate?: RealEstateDTO | any) {
    this.realEstate = realEstate;
    if (!this.validationDataService.getIsCalculating()) {
      return this.setCorrectSliderModelValues2(usages, this.realEstate).pipe(
        tap(() => {
          this.roomHeightLimitsWarningService.showWarningIfOutOfLimit(this.realEstate);
        }),
      );
      return of(this.realEstate);
    }
    return of(this.realEstate);
  }

  setCorrectSliderModelValues2(
    usages: RealEstateUsageEmbeddable[],
    realEstate?: RealEstateDTO,
  ): Observable<RealEstateDTO> {
    if (realEstate != null) {
      this.realEstate = realEstate;
    }
    if (this._routeUtil.isRouteMap('input')) {
      this.isPresetSliderModelValuesEnabled = true;
    } else {
      this.isPresetSliderModelValuesEnabled = false;
    }

    let result$ = of(this.realEstate);
    var correctUsages = this.sliderPresetService.correctUsages(usages);
    if (correctUsages.length == 0) {
      return result$;
    }

    if (this.isPresetSliderModelValuesEnabled && correctUsages) {
      result$ = result$.pipe(
        switchMap(() => this.facadePresetService.presetFacade2(correctUsages, this.realEstate)),
        switchMap(() => this.minergieTypePresetService.presetMinergieType2(correctUsages, this.realEstate)),
        switchMap(() => this.minergieEcoPresetService.presetMinergieEco2(correctUsages, this.realEstate)),
        switchMap(() => this.roofTypePresetService.presetRoofType2(correctUsages, this.realEstate)),
      );
    }

    return result$.pipe(
      untilDestroyed(this),
      tap((value) => {
        console.warn('setCorrectSliderModelValues2: ' + value);
      }),
      switchMap(() =>
        this.sliderPresetService.getWeightedPresetValues2(correctUsages, this.realEstate).pipe(
          untilDestroyed(this),
          filter((weightedArr) => !!weightedArr),
          switchMap((weightedArr) =>
            this.sliderPresetService.getWeightedPresetIntervals2(correctUsages).pipe(
              take(1),
              untilDestroyed(this),
              tap((weightedIntervalArr) => {
                this.sliderPresetService.setSliderModelValuesArr2(
                  weightedArr,
                  weightedIntervalArr,
                  this.realEstate,
                  this.isPresetSliderModelValuesEnabled,
                );
                if (this.realEstate.replacementOriginalRealEstateId != null) {
                  var minimumVorbereitungsArbeiten = this.realEstate.geometry.totalFloorArea416 < 1000 ? 4 - 1 : 5 - 1;
                  if (weightedArr.qualitaetvorbereitungsarbeiten < minimumVorbereitungsArbeiten) {
                    console.info(
                      'Overwrite preset of Vorbereitungsarbeiten from ' +
                        weightedArr.qualitaetvorbereitungsarbeiten +
                        ' to ' +
                        minimumVorbereitungsArbeiten +
                        ' because current real estate is a replacement ("Ersatzbau")',
                    );
                    this.realEstate.quality.qualitaetvorbereitungsarbeiten =
                      this.sliderIdentMappingService.getSliderIdentByMore(minimumVorbereitungsArbeiten);
                  }
                }
              }),
              map(() => this.realEstate),
              catchError(() => {
                return EMPTY;
              }),
            ),
          ),
          catchError(() => {
            return EMPTY;
          }),
        ),
      ),
      catchError(() => {
        return EMPTY;
      }),
    );
  }
}
